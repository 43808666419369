import { ExpandMore, ViewList, ViewModule } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { SearchBar } from 'components';
import { PersistentState, usePersistentState } from 'hooks/usePersistentState';
import { groupBy, map, sortBy, uniqBy } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { maybe } from 'system/shapes';
import { z } from 'zod';
import { GridContainer, ListContainer, Title } from './ListGrid.styles';

const groupedItem = z.object({
  group: z.object({
    name: z.string(),
    order: maybe(z.number()),
  }),
});

export default function ListGrid<T>({
  items,
  renderListItem,
  renderGridItem,
  onSearchEntered,
  ungroupedPosition = 'last',
}: {
  items: T[];
  renderListItem: (item: T) => ReactNode;
  renderGridItem: (item: T) => ReactNode;
  onSearchEntered: (searchTerm: string) => void;
  ungroupedPosition?: 'last' | 'first';
}) {
  const [view, setView] = usePersistentState<'grid' | 'list'>(PersistentState.listGrid, 'list');

  const [groupNames, itemsByGroup] = useMemo(() => {
    const names = map(
      sortBy(
        uniqBy(
          items.map((item) => {
            const parsedResult = groupedItem.safeParse(item);
            return parsedResult.success ? parsedResult.data.group : { name: 'Other' };
          }),
          'name'
        ),
        'order'
      ),
      'name'
    );

    const grouped = groupBy(items, (i) => {
      const parsedItem = groupedItem.safeParse(i);
      return parsedItem.success ? parsedItem.data.group.name : 'Other';
    });

    return [names, grouped];
  }, [items]);

  const ungroupedItems = itemsByGroup['Other'] ? (
    <ListContainer>
      {itemsByGroup['Other'].map((item, index) => (
        <div key={index}>{renderListItem(item)}</div>
      ))}
    </ListContainer>
  ) : null;

  const [Container, itemRenderer] =
    view === 'grid' ? [GridContainer, renderGridItem] : [ListContainer, renderListItem];

  return (
    <>
      <Title>
        <SearchBar placeholder="Search" initialSearch="" onSearchEntered={onSearchEntered} />
        <ToggleButtonGroup
          size="small"
          value={view}
          exclusive
          onChange={(_: React.MouseEvent<HTMLElement>, nextView: string) =>
            setView(nextView === 'list' ? 'list' : 'grid')
          }
        >
          <ToggleButton value="list" aria-label="list">
            <ViewList fontSize="small" color={view === 'list' ? 'primary' : 'action'} />
          </ToggleButton>
          <ToggleButton value="grid" aria-label="grid">
            <ViewModule fontSize="small" color={view === 'grid' ? 'primary' : 'action'} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Title>
      {ungroupedPosition === 'first' && ungroupedItems}
      {groupNames
        .filter((n) => n !== 'Other')
        .map((groupName) => (
          <Accordion key={groupName} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h5">{groupName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
                {itemsByGroup[groupName].map((item, index) => (
                  <div key={index}>{itemRenderer(item)}</div>
                ))}
              </Container>
            </AccordionDetails>
          </Accordion>
        ))}
      {ungroupedPosition === 'last' && ungroupedItems}
    </>
  );
}
