import { FileUpload } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { useAuth } from 'context';
import { useModalControl } from 'hooks/useModalControl';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ImportJournalsModalFormFields,
  ImportJournalsRowModel,
  JournalImportType,
} from '../tasks/ImportJournals/types';
import { TaskListItem } from '../types';
import { useGlAccounts } from './useGlAccounts';

export const useImportJournals = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { searchGlIdByName } = useGlAccounts();

  const [openImportJournalsModal, hideImportJournalsModal, importJournalsModalOpen] =
    useModalControl<JournalImportType>();

  const { isBooksAdmin } = useAuth();

  const importJournalsTask: TaskListItem | null = useMemo(
    () =>
      isBooksAdmin
        ? {
            id: 'import-journals',
            group: { name: 'General Ledger', order: 3 },
            icon: <FileUpload htmlColor={theme.palette.quinary.main} />,
            title: 'Import',
            onClick: () => openImportJournalsModal(),
            subtitle: 'Import journal entries from a CSV data file',
          }
        : null,
    [openImportJournalsModal, theme.palette.quinary.main, isBooksAdmin]
  );

  const submitImportJournalsModal = useCallback(
    (importData: ImportJournalsModalFormFields) => {
      navigate('/accounting/tasks/import-journals', {
        state: {
          importData: {
            ...importData,
            entries: importData.entries.map<ImportJournalsRowModel>(
              (
                { creditAccountName, accountName, debitAccountName, date: posted, ...entry },
                index
              ) => ({
                ...entry,
                posted,
                index,
                ...(importData.importType === JournalImportType.OpeningBalances
                  ? {
                      glId: searchGlIdByName(accountName),
                    }
                  : {
                      creditGlId: searchGlIdByName(creditAccountName),
                      debitGlId: searchGlIdByName(debitAccountName),
                    }),
              })
            ),
          },
        },
      });
    },
    [navigate, searchGlIdByName]
  );

  return {
    importJournalsTask,
    importJournalsModalOpen,
    openImportJournalsModal,
    hideImportJournalsModal,
    submitImportJournalsModal,
  };
};
