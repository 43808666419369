import { useTheme } from '@mui/material';
import { ManagerRole, useListReconciliationsQuery } from 'api';
import { Abacus } from 'components/icons';
import { useAuth } from 'context';
import { useMeta } from 'hooks/useMeta';
import { useModalControl } from 'hooks/useModalControl';
import { usePaginateAllQuery } from 'hooks/usePaginateAllQuery';
import { orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ensureArray } from 'system';
import { TaskListItem } from '../types';

const RECONCILIATION_ROLES = [ManagerRole.BooksAdmin, ManagerRole.BooksAdvanced];

export const useReconciliations = () => {
  const navigate = useNavigate();
  const { includesAnyRole } = useAuth();
  const allowReconciliation = includesAnyRole(...RECONCILIATION_ROLES);

  const { items: reconciliations, ...meta } = usePaginateAllQuery(useListReconciliationsQuery, {
    skip: !allowReconciliation,
    getNextToken: (data) => data?.account?.books?.listReconciliations?.nextToken,
    getItems: (data) =>
      orderBy(
        ensureArray(data?.account?.books?.listReconciliations?.items),
        ['statementDate', 'configuration', 'current'],
        ['desc', 'desc', 'asc']
      ),
  });

  const { loading } = useMeta(meta);
  const theme = useTheme();

  const task = useMemo(
    () => ({
      id: 'account-reconciliation',
      group: { name: 'General Ledger', order: 3 },
      icon: <Abacus htmlColor={theme.palette.quinary.main} />,
      title: 'Account Reconciliation',
      subtitle: 'Reconcile accounts with bank statements',
      onClick: (id: string) => navigate(`/accounting/tasks/${id}`),
    }),
    [navigate, theme.palette.quinary.main]
  );

  const [taskListItem, setTaskListItem] = useState<TaskListItem>(task);
  const [
    showReconciliationPresetModal,
    hideReconciliationPresetModal,
    isReconciliationPresetModalOpen,
  ] = useModalControl();

  useEffect(() => {
    setTaskListItem({
      ...task,
      onClick: () => {
        showReconciliationPresetModal();
      },
      menu: [
        {
          actionName: 'Manage Groups',
          onActionClick: () => {
            navigate('/accounting/tasks/account-reconciliation/groups');
          },
        },
        {
          actionName: 'View Past Reconciliations',
          onActionClick: () => {
            navigate('/accounting/tasks/account-reconciliation/history');
          },
        },
      ],
      roles: RECONCILIATION_ROLES,
    });
  }, [navigate, showReconciliationPresetModal, task]);

  return {
    loading: allowReconciliation ? false : loading,
    taskListItem: allowReconciliation ? taskListItem : null,
    reconciliations,
    showReconciliationPresetModal,
    isReconciliationPresetModalOpen,
    hideReconciliationPresetModal,
  };
};
