import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { RadioField, RadioFieldProps } from '../Fields/RadioField';

export default function RadioFieldController<
  T extends FieldValues = Record<string, undefined>,
  TId extends string = string,
>({ name, onChange, disabled, ...props }: RadioFieldProps<TId> & { name: Path<T> }) {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <RadioField
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          value={field.value ?? null}
          onChange={(event, checked) => {
            field.onChange(checked);
            onChange?.(event, checked);
          }}
          disabled={disabled || isSubmitting}
          {...props}
        />
      )}
    />
  );
}
