import { eventFactory } from '@propra-system/shape-handler';
import { maybe } from '@propra-system/shapes-util/src/maybe';
import { z } from 'zod';

export const { managerAutopayActivationChangedEvent } = eventFactory(
  'manager-autopay-activation-changed-event',
  z.object({
    accountId: z.string(),
    activated: z.boolean(),
    activatedZ: maybe(z.string()),
    activationUrl: maybe(z.string()),
    activationStatus: z.string(),
    activationStatusUpdatedZ: z.string(),
  })
);
