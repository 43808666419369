import { ReactiveVar } from '@apollo/client';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { BalanceType, ManagerRole } from 'api';
import { ReactNode } from 'react';

export type FeeReportScope = 'property';

export type TaskListItem = {
  id: string;
  title: string;
  group?: { name: string; order?: number };
  recurring?: boolean;
  locked?: boolean;
  icon: ReactNode;
  onClick: (id: string) => void;
  subtitle?: string;
  menu?: {
    actionName: string;
    onActionClick: (id: string) => void;
  }[];
  decoration?: ReactNode;
  roles?: Array<ManagerRole>;
};

export type ReportList = {
  icon: ReactNode;
  title: string;
  id: string;
  onClick: (id: string) => void;
  subtitle?: string;
};

export enum TimeframeValues {
  'month' = 'This month',
  'quarter' = 'This quarter',
  'year' = 'This year',
  'last-month' = 'Last month',
  'last-quarter' = 'Last quarter',
  'last-year' = 'Last year',
  'all' = 'All',
  'custom' = 'Custom',
}

export type Timeframe = keyof typeof TimeframeValues;

export type ClearableLabels = {
  balanceType: BalanceType;
  tableState: ReactiveVar<GridInitialStatePro>;
  clearableLabel: string;
  clearingEntryLabel: string;
  clearingEntryDescription: string;
  clearablePath: string;
  clearingEntryPath: string;
  clearableDocumentLabel: string;
};

export enum LinkedEntryDisableMessage {
  Delete = 'Cannot delete a linked entry.',
  Edit = 'Cannot edit a linked entry.',
}

export enum ReconciledEntryDisableMessage {
  NSF = 'Cannot mark a reconciled entry as NSF.',
  Delete = 'Cannot delete a reconciled entry.',
  Edit = 'Cannot edit a reconciled entry.',
  Void = 'Cannot void a reconciled entry.',
}

export enum AutopayEntryDisableMessage {
  NSF = 'Cannot mark an autopay entry as NSF.',
  Delete = 'Cannot delete an autopay entry.',
  Edit = 'Cannot edit an autopay entry.',
  Void = 'Cannot void an autopay entry.',
}

export enum VoidedEntryDisableMessage {
  NSF = 'Cannot mark a voided entry as NSF.',
  Void = 'Cannot void a voided entry.',
}

export enum SelectionMode {
  OwnerFirst = 'ownerFirst',
  PropertyFirst = 'propertyFirst',
  OnlyOwner = 'onlyOwner',
  OnlyProperty = 'onlyProperty',
}
