import {
  Button as _Button,
  ButtonProps,
  CircularProgress,
  IconButton,
  styled,
} from '@mui/material';
import { ReactNode } from 'react';
import { Tooltip } from './Tooltip';

export type LoadingButtonProps = ButtonProps & {
  icon?: ReactNode;
  loading?: boolean;
  hideProgress?: boolean;
  disabledTooltip?: string;
};

const Button = styled(_Button)(({ size }) => ({
  fontWeight: 500,
  fontSize: size === 'small' ? 12 : 14,
}));

export default function LoadingButton({
  loading = false,
  children,
  disabled,
  hideProgress,
  disabledTooltip,
  icon,
  ...props
}: LoadingButtonProps) {
  const button = icon ? (
    <IconButton {...props} disabled={disabled || loading}>
      {loading && !hideProgress ? <CircularProgress size={24} /> : icon}
    </IconButton>
  ) : (
    <Button {...props} disabled={disabled || loading} disableElevation>
      {loading && !hideProgress ? <CircularProgress size={24} /> : children}
    </Button>
  );

  return disabledTooltip ? <Tooltip title={disabledTooltip}>{button}</Tooltip> : button;
}
