import { CompareArrows } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { AddJournalEntryInput, useTransferJournalEntriesMutation } from 'api';
import { useAuth } from 'context';
import { useMeta } from 'hooks/useMeta';
import { useNotification } from 'hooks/useNotification';
import { compact } from 'lodash';
import { TaskListItem } from 'pages/accounting/types';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UploadFilesFunction } from '../useAccountingFiles';

export const useTransfer = () => {
  const { isBooksAdvanced: transferEnabled } = useAuth();

  const theme = useTheme();
  const navigate = useNavigate();
  const transferTask: TaskListItem | null = useMemo(
    () =>
      transferEnabled
        ? {
            id: 'transfers',
            title: 'Transfers',
            group: { name: 'General Ledger', order: 3 },
            subtitle: 'Transfer funds between properties',
            onClick: (id: string) => navigate(`/accounting/tasks/${id}`),
            icon: <CompareArrows htmlColor={theme.palette.quaternary.main} />,
          }
        : null,
    [transferEnabled, navigate, theme.palette.quaternary.main]
  );

  const { sendNotification } = useNotification();
  const [transferJournalEntries, transferJournalsMeta] = useTransferJournalEntriesMutation();
  const { loading: transferring } = useMeta(transferJournalsMeta);

  const transfer = useCallback(
    async (
      [source, destination]: [AddJournalEntryInput, AddJournalEntryInput?],
      filesToUpload: File[],
      uploadFiles: UploadFilesFunction,
      { notification = true }: { notification?: boolean } = {}
    ) => {
      let ids: string[] = [];
      try {
        const result = await transferJournalEntries({
          variables: { input: { source, destination, selfTransfer: !destination } },
        });
        if (notification) sendNotification('Journal entry has been posted', 'success');

        const sourceId = result?.data?.transferJournalEntries?.sourceJournalIds?.[0];
        const destinationId = result?.data?.transferJournalEntries?.destinationJournalIds?.[0];
        ids = compact([sourceId, destinationId]);
      } catch (e) {
        console.error(e);
        if (notification) sendNotification('Validation error, journal entry not posted', 'error');
        return false;
      }

      try {
        if (ids.length && filesToUpload.length) {
          await Promise.all(ids.map((id) => uploadFiles({ id, resetOnSuccess: false })));
        }
      } catch (e) {
        console.error(e);
        if (notification) sendNotification('There was an error uploading files', 'error');
      }

      return ids;
    },
    [sendNotification, transferJournalEntries]
  );

  return { transferEnabled, transferTask, transfer, transferring };
};
