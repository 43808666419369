import { useCallback, useState } from 'react';
import { emptyArray } from 'system';
import { ClearableFilterFormFields } from '../clearables/ClearableFilters';
import { usePropertyOwnerContext } from '../context/usePropertyOwnerContext';

export const useClearableFilters = (defaults?: Partial<ClearableFilterFormFields>) => {
  const { ownerId, propertyId, setPropertyOwner } = usePropertyOwnerContext();

  const [filters, setLocalFilters] = useState<ClearableFilterFormFields>({
    timeframe: 'all',
    status: 'outstanding',
    ownerId: Array.isArray(ownerId) ? ownerId : ownerId ? [ownerId] : emptyArray,
    propertyId: Array.isArray(propertyId) ? propertyId : propertyId ? [propertyId] : emptyArray,
    types: [],
    ...defaults,
  });

  const setFilters = useCallback(
    (newFilters: ClearableFilterFormFields) => {
      setLocalFilters(newFilters);
      setPropertyOwner({
        ownerId: newFilters.ownerId,
        propertyId: newFilters.propertyId,
      });
    },
    [setPropertyOwner]
  );

  return { filters, setFilters };
};
