import { PersistentState, usePersistentState } from 'hooks/usePersistentState';
import { uniq } from 'lodash';
import { ReactNode, createContext, useCallback, useContext } from 'react';
import { ifDifferent } from 'system';

export type PropertyOwnerContextType = {
  ownerId: string | string[] | undefined;
  propertyId: string | string[] | undefined;
  setPropertyOwner: (state: {
    ownerId: string | string[] | undefined;
    propertyId: string | string[] | undefined;
  }) => void;
};

const initialState: PropertyOwnerContextType = {
  ownerId: undefined,
  propertyId: undefined,
  setPropertyOwner: () => undefined,
};

const PropertyOwnerContext = createContext<PropertyOwnerContextType>(initialState);

export const usePropertyOwnerContext = () => {
  const context = useContext(PropertyOwnerContext);
  if (!context) {
    throw new Error('usePropertyOwnerContext must be used within an PropertyOwnerProvider');
  }

  return context;
};

export const PropertyOwnerProvider = ({ children }: { children: ReactNode }) => {
  const [{ ownerId, propertyId }, _setPropertyOwner] = usePersistentState<{
    ownerId: string | string[] | undefined;
    propertyId: string | string[] | undefined;
  }>(PersistentState.propertyOwner, { ownerId: [], propertyId: [] });

  const setPropertyOwner = useCallback(
    (state: {
      ownerId: string | string[] | undefined;
      propertyId: string | string[] | undefined;
    }) => {
      _setPropertyOwner(
        ifDifferent({
          ownerId: Array.isArray(state.ownerId) ? uniq(state.ownerId) : state.ownerId,
          propertyId: Array.isArray(state.propertyId) ? uniq(state.propertyId) : state.propertyId,
        })
      );
    },
    [_setPropertyOwner]
  );

  return (
    <PropertyOwnerContext.Provider
      value={{
        ownerId,
        propertyId,
        setPropertyOwner,
      }}
    >
      {children}
    </PropertyOwnerContext.Provider>
  );
};
