import { InMemoryCache } from '@apollo/client';
import { managerApprovalDeclinedEvent } from '@propra-manager/shapes/approval/managerApprovalDeclinedEvent';
import { managerAutopayActivationChangedEvent } from '@propra-manager/shapes/autopay/managerAutopayActivationChangedEvent';
import { managerAutopayPaymentsPrototypeCompletedEvent } from '@propra-manager/shapes/autopay/payments/prototypeCompletedEvent';
import { shapeHandler } from '@propra-system/shape-handler';
import { ApprovalType } from 'api';
import { constant } from 'lodash';
import { invalidate } from 'system';

export const cacheShapeHandlers = (cache: InMemoryCache, accountId: string) => [
  shapeHandler(managerAutopayPaymentsPrototypeCompletedEvent, () => {
    cache.modify({
      id: cache.identify({ accountId, __typename: 'Books' }),
      fields: {
        pageClearables: invalidate,
      },
    });

    return Promise.resolve();
  }),
  shapeHandler(managerApprovalDeclinedEvent, ({ detail: { type } }) => {
    if (type === ApprovalType.Payments) {
      cache.modify({
        id: cache.identify({ accountId, __typename: 'Books' }),
        fields: {
          pageClearables: invalidate,
        },
      });
    }

    return Promise.resolve();
  }),
  shapeHandler(
    managerAutopayActivationChangedEvent,
    async ({ detail: { activated, activatedZ, activationUrl, activationStatus } }) => {
      cache.modify({
        id: cache.identify({ accountId, __typename: 'Autopay' }),
        fields: {
          activationStatus: constant(activationStatus),
          activated: constant(activated),
          activatedZ: constant(activatedZ),
          activationUrl: constant(activationUrl),
        },
      });
    }
  ),
];
