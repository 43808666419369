export const autopayDetails = (item?: {
  autopayInstitution?: string;
  autopayAccount?: string;
  autopayTransit?: string;
  autopayStatus?: string;
  autopayClientId?: string;
  autopayInvitedZ?: string;
  autopayCollectionsEnabled?: boolean;
}) => {
  const {
    autopayInstitution,
    autopayAccount,
    autopayTransit,
    autopayStatus,
    autopayClientId,
    autopayInvitedZ,
    autopayCollectionsEnabled,
  } = item ?? {};

  return (autopayClientId && autopayStatus) ||
    (autopayAccount && autopayTransit && autopayInstitution)
    ? {
        autopayClientId,
        autopayStatus,
        autopayInvitedZ,
        autopayInstitution,
        autopayAccount,
        autopayTransit,
        autopayCollectionsEnabled,
      }
    : undefined;
};
