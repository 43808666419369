import { useAllPropertiesQuery } from 'api';
import { useCallback, useMemo } from 'react';
import { ensureArray } from 'system';
import { useMeta } from './useMeta';
import { useTeams } from './useTeams';

export const useProperties = () => {
  const meta = useAllPropertiesQuery();
  const { loading } = useMeta(meta);

  const { isPropertyVisible } = useTeams();

  const properties = useMemo(
    () =>
      ensureArray(
        meta.data?.account?.pageProperties?.edges
          ?.map(({ node }) => node)
          .filter(({ id }) => isPropertyVisible(id))
      ),
    [isPropertyVisible, meta.data?.account?.pageProperties?.edges]
  );

  const disabledAutopayPropertyIds = useMemo(
    () => new Set(properties.filter((p) => p.autopayCollectionsEnabled === false).map((p) => p.id)),
    [properties]
  );

  const propertyAutopayEnabled = useCallback(
    (propertyId: string) => !disabledAutopayPropertyIds.has(propertyId),
    [disabledAutopayPropertyIds]
  );

  return { loading, properties, propertyAutopayEnabled };
};
